import React, { useState, useContext } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutline, ReplayOutlined, GetApp } from '@material-ui/icons';

import { userRows } from "../../dummydata"
import { Link } from 'react-router-dom'
import "./userList.css"
import Timer from "./Timer"
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Context } from '../../context/Context';
import axios from 'axios';
import { toast } from 'react-toastify'
import HoverableDiv from './HoverableDiv'
import { Modal } from '../../components/ModalCreate/Modal';
import styled from 'styled-components'
import moment from 'moment';
import download from 'js-file-download';
import ReactTooltip from "react-tooltip";


export default function Userlist(props) {
  const { sessions, setReloadSessions } = props

  const { user } = useContext(Context);
  const [data, setData] = useState(userRows)
  const [credentials, setShowCredentials] = useState('');

  if (user.user.role === 'user') {
    try {
      if (sessions) {
        for (let i = 0; i < sessions.length; i++) {
          let dateNow = Date.now();
          const checkDateNow = moment(dateNow).diff(sessions[i].createdAt, 'days');
          if (checkDateNow >= 10) {
            sessions.splice(sessions[i], 1)
          }
        }
      }
    }catch(e){
      console.log(e)
    }
  }

  const handleDelete = (id) => {
    // setData(data.filter((item) => item.id !== id));
    // //console.log('Deleting');
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              // const res = await axios.post("/auth/register", {
              // const res = await axios.post(`${process.env.REACT_APP_API}/signup`, {
              // const res = await axios.post("http://localhost:8000/api/submitSession", state);
              //console.log(id)
              const { data } = await axios.delete(`/api/remove/${id}`,
                {
                  headers: {
                    'Authorization': `Bearer ${user.token}`
                  }
                });

              toast.success('Update successful!', {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              //console.log(data)
              setReloadSessions(true)

            } catch (err) {
              //console.log(err)
              toast.success(`${err}`, {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

            }
          }
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ]
    });
  };

  const handleResetDb = async (id) => {
    // setData(data.filter((item) => item.id !== id));
    // //console.log('Deleting');
    confirmAlert({
      title: 'Confirm to Reset the Database',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              // const res = await axios.post("/auth/register", {
              // const res = await axios.post(`${process.env.REACT_APP_API}/signup`, {
              // const res = await axios.post("http://localhost:8000/api/submitSession", state);
              //console.log(id)
              const { data } = await axios.post(`/api/session/reset/${id}`,
                {

                },{                  headers: {
                  'Authorization': `Bearer ${user.token}`
                }});

              toast.success('Update successful!', {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              //console.log(data)


            } catch (err) {
              //console.log(err)
              toast.success(`${err}`, {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

            }
          }
        },
        {
          label: 'No',
          onClick: () => console.log('Click No')
        }
      ]
    });
  };
  const handleDownloadLog = async (id) => {

    // try {
    //console.log(id)
    // const { data } =
    await axios.get(`/api/session-log/${id}`,
      {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        // download(resp.data, "inserviceofgod_debug.log")
        download(resp.data, "session_details.log")
        toast.success('Download successful!', {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(error => {
        toast.error(`${error.response.data.error}`, {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })


    // //console.log(data)


    // } catch (err) {
    //   //console.log(err)
    //   toast.success(`${err.response.data.error}`, {
    //     position: "top-center",
    //     autoClose: 2500,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });

    // }


  };

  const handleExpired = (id) => {
    // setData(data.filter((item) => item.id !== id));
    // //console.log('Deleting');
    confirmAlert({
      title: 'Confirm to renew Session',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              // const res = await axios.post("/auth/register", {
              // const res = await axios.post(`${process.env.REACT_APP_API}/signup`, {
              // const res = await axios.post("http://localhost:8000/api/submitSession", state);
              //console.log(id)
              const { data } = await axios.post(`/api/renew-session/${id}`,
                {
                  headers: {
                    'Authorization': `Bearer ${user.token}`
                  }
                });

              toast.success('Update successful!', {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              //console.log(data)
              setReloadSessions(true)

            } catch (err) {
              //console.log(err)
              toast.success(`${err}`, {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

            }
          }
        },
        {
          label: 'No',
          onClick: () => console.log('no decided')
        }
      ]
    });
  };

  const columns = [
    // { field: '_id', headerName: 'ID', width: 250 },
    {
      field: 'host', headerName: 'Domain', width: 200, renderCell: (params) => {
        return (
          <div className="userListUser">
            {/* <img className="userListImg" src={params.row.avatar} alt="" /> */}
            <a href={`http://${params.row.hostname}`} target="_blank" >{params.row.hostname}</a>
          </div>
        )
      }
    },
    { field: 'username', headerName: 'cPanel Username', width: 190 },
    {
      field: 'createdAt',
      headerName: 'Expires in',
      // type: 'password',
      width: 120,
      renderCell: (params) => {
        var dateNow = Date.now();
        const checkDateNow = moment(dateNow).diff(params.row.createdAt, 'days');
        //console.log(checkDateNow - 10)
        return (
          <>

            {user.user.role == 'user' && (
              `${10 - checkDateNow} Days `
            )}
            {user.user.role == 'admin' && (
              <>
                {checkDateNow - 10 >= 0 ? (

                  <button className="userListExpired" onClick={() => handleExpired(params.row._id)}>Expired</button>

                ) : `${10 - checkDateNow} Days`}
              </>
            )}
          </>
        )
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      //   type: 'number',
      width: 200,
    },
    // {
    //   field: 'password',
    //   headerName: 'cPanel Password',
    //   // type: 'password',
    //   width: 160,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <HoverableDiv password={params.row.password} />


    //       </>

    //     )
    //   }
    // },
    {
      field: 'createdAt',
      headerName: 'Created',
      // type: 'password',
      width: 255,
      renderCell: (params) => {
        var result
        if(params.row.endAt){
          // result = 30
          var start = moment(params.row.createdAt);
          var end = moment(params.row.endAt);
          console.log(start)
          console.log(end)
          var duration = moment.duration(end.diff(start));
          var days = duration.asMinutes();
          console.log(days)
          var startDate = moment(params.row.createdAt).format(" MMMM Do YYYY");
          result = `${startDate}, created in ${days.toFixed(2)} min`
        } else {

           result = moment(params.row.createdAt).format(" MMMM Do YYYY");
          // const checkDateNow = moment(params.row.createdAt).format(" MMMM Do YYYY");
        }
        // const checkDateNow = moment(params.row.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a");
        return (
          <>

            {result}
            {/* {params.row.email} */}
            {/* {params.row.createdAt.moment().format("ddd, hA")} */}
            {/* <Timer/> */}
          </>

        )
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 270,
      renderCell: (params) => {
        return (
          <>

                     <ReactTooltip id="registerTip" place="left" effect="solid">
                         Launch HUI
                         </ReactTooltip>
           <ReactTooltip id="downloadtip" place="left" effect="solid">
           Download logs
           </ReactTooltip>
           <ReactTooltip id="deleteTip" place="left" effect="solid">
           Remove HUI Connection
           </ReactTooltip>
           <ReactTooltip id="resetTip" place="left" effect="solid">
           Restore Database from Template
           </ReactTooltip>
           {params.row.endAt && (
              <Link to={"/session/" + params.row._id}>
              <button className="userListEdit" data-tip data-for="registerTip">Launch</button>
            </Link>
    )}

  
            <button className="userListEdit" onClick={() => {
              setShowModal(prev => !prev)
              setShowCredentials(params.row)
            }} >Credentials</button>
            {user.user.role == 'user' ? '' : (
              <>
                <DeleteOutline data-tip data-for="deleteTip"
                  className="userListDelete"
                  onClick={() => handleDelete(params.row._id)}
                />
                <GetApp data-tip data-for="downloadtip"
                  className="userListDownload"
                  onClick={() => handleDownloadLog(params.row._id)}
                />
                <ReplayOutlined data-tip data-for="resetTip"
                  className="userListReset"
                  onClick={() => handleResetDb(params.row._id)}
                />
              </>
            )}
          </>

        )
      }
    }

  ];

  const [showModal, setShowModal] = useState(false);


  const openModal = () => {
    setShowModal(prev => !prev);
  };

  const StyledForm = styled.div`
  width: 100%;
  max-width: 700px;
  padding: 40px;
  /* background-color: #fff; */
  border-radius: 10px;
  box-sizing: border-box;
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  input{
    background-color: #d1cfcf;
  }
  >button{
    margin-top: 20px;
  }
`;




  return (
    <div className="userList">
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <StyledForm>
          <h3>cPanel Details:</h3>
          <label htmlFor="email"><strong>Username</strong></label>
          <label htmlFor="email">{credentials.username}</label>
          <label htmlFor="email"><strong>Password</strong></label>
          <label htmlFor="email">{credentials.password}</label>
          <h3>FTP Details:</h3>
          <label htmlFor="email"><strong>Domain Name</strong></label>
          <label htmlFor="email">{credentials.hostname}</label>
          {/* <label htmlFor="email"><strong>Port</strong></label>
          <label htmlFor="email">{credentials.port}</label> */}
          <h3>Database Details:</h3>
          <label htmlFor="email"><strong>Database User</strong></label>
          <label htmlFor="email">{credentials.database_user}</label>
          <label htmlFor="email"><strong>Database Name</strong></label>
          <label htmlFor="email">{credentials.database_name}</label>
          <label htmlFor="email"><strong>Database Password</strong></label>
          <label htmlFor="email">{credentials.database_password}</label>

        </StyledForm>


      </Modal>
      {sessions ? <DataGrid rows={sessions} getRowId={(row) => row._id} disableSelectionOnClick columns={columns} pageSize={10}  /> : <> No data </>}
      {/* {sessions.servers?.length === 0 ? (
                <div>empty</div>
              ): (
               <DataGrid rows={sessions.servers} getRowId={(row) => row._id} disableSelectionOnClick columns={columns} pageSize={10} checkboxSelection /> 
                // <div>hola</div>
              )} */}
      {/* <DataGrid rows={userRows} disableSelectionOnClick columns={columns} pageSize={10} checkboxSelection /> */}
    </div>
  )
}
