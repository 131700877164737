import React, { useState, useContext } from 'react';
import styled, { createGlobalStyle, css, keyframes } from 'styled-components';
import Input from '../Input';
import axios from "axios";
import { toast } from 'react-toastify';
import { Context } from '../../context/Context'
import logo from "../../assets/logoHB.png";
import $ from 'jquery';


const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const sharedStyles = css`
  background-color: #eee;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;

const ModalWrapper = styled.div`
  // width: 800px;
  // height: 500px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 10;
  border-radius: 10px;
`;

const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;
  display: ${props => props.visibility ? 'none' : 'block'}
`;

const StyledFormWrapper = styled.div`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // height: 100vh;
  // padding: 0 20px;
  // width: 80%;
  max-width: 350px;
  min-width: 250px;
  // height: 40px;
  border: none;
  margin: 0.5rem 0;
  background-color: #f5f5f5;
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease-in;
  &:hover {
    transform: translateY(-3px);
  }
`;

const StyledForm = styled.form`
  // width: 100%;
  width: 400px;
  // max-width: 700px;
  padding: 40px;
  /* background-color: #fff; */
  border-radius: 10px;
  box-sizing: border-box;
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: column;
  input{
    background-color: #d1cfcf;
  }
  >button{
    margin-top: 20px;
  }
  label{
    width:100%;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  max-width: 350px;
  min-width: 250px;
  height: 40px;
  border: none;
  margin: 0.5rem 0;
  background-color: #f5f5f5;
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease-in;
  &:hover {
    transform: translateY(-3px);
  }
`;

const StyledTextArea = styled.textarea`
  background-color: #eee;
  width: 100%;
  min-height: 100px;
  resize: none;
  ${sharedStyles}
`;
const StyledButton = styled.button`
  display: block;
  // background-color: #f7797d;
  background-color: #031b56 !important;
  color: #fff;
  font-size: 0.9rem;
  border: 0;
  border-radius: 5px;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border-box;
  ${props =>
    props.disabled ?
      `
    background: #ABA9BB !important;
    `: `
    background: #031b56 !important;
    `};
`;

const StyledFieldset = styled.fieldset`
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 0;
  legend {
    padding: 0 10px;
  }
  label {
    padding-right: 20px;
  }
  input {
    margin-right: 10px;
  }
`;

const StyledError = styled.div`
  color: red;
  font-weight: 800;
  /* margin: 0 0 40px 0; */
`;

const Select = styled.select`
  width: 80%;
  max-width: 350px;
  min-width: 250px;
  height: 40px;
  border: none;
  margin: 0.5rem 0;
  background-color: #f5f5f5;
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease-in;
  &:hover {
    transform: translateY(-3px);
  }
`
const Title = styled.h5`
margin: 10px;
// font-size:12px;
/* margin-top: 0; */
@media only screen and (max-width: 480px) {
  margin: 20px;
}
color: black;
// &:hover{
//     color:#CC9900;
// }
`;

const initalState = {
  host: '',
  username: '',
  email: '',
  // password: '',

};

function SubmitBusinessForm(props) {
  const { setReloadSessions, closeModal, whmData } = props;
  //console.log(props)
  const { user, dispatch } = useContext(Context);
  const [state, setState] = useState(initalState);
  const [error, setError] = useState('');
  const [finish, setFinish] = useState(false);
  const [spin, setSpin] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const icons = { 'success': '✅', 'fail': '🛑' }

  const saveSessionCall = async (sessionData, token) => {
    try {
      //console.log(sessionData)
      const { data } = await axios.post('/api/session/save', sessionData, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
      $("#console").val(JSON.stringify(data));
      if (data.hasOwnProperty('metadata')) {
        let msg = (JSON.stringify(data.metadata.reason.includes("is not a valid username")) ? 'Please choose a different username.' : JSON.stringify(data.metadata.reason));
        toast.error(msg, {
          position: "top-center",
          autoClose: 12500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        let error = msg
        var error1 = {
          error
        }
        return error1
      } else {

        var result = data
        setReloadSessions(true)

        return result
      }

    } catch (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 12500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    }
  }

  //Start DB Provision
  const dbSessionCall = async (sessionData, token) => {
    //console.log('start db provision')
    //console.log(sessionData)
    //console.log(sessionData)

    try {
      const { data } = await axios.post('/api/session/db', sessionData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      //console.log('the response')
      //console.log(data)
      //we got a provision error
      $("#console").val(JSON.stringify(data));
      if (data.hasOwnProperty('metadata')) {
        let msg = (JSON.stringify(data.metadata.reason.includes("is not a valid username")) ? 'Please choose a different username.' : JSON.stringify(data.metadata.reason));
        toast.error(msg, {
          position: "top-center",
          autoClose: 12500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        let error = msg
        var error1 = {
          error
        }
        return error1
      } else {

        var result = data
        return result
      }
    } catch (error) {
      //console.log(error)
      toast.error(error, {
        position: "top-center",
        autoClose: 12500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  //Start DB Provision
  const dbAccountSessionCall = async (sessionData, token) => {
    //console.log('start db account provision')
    //console.log(sessionData)
    //console.log(sessionData)

    try {
      const { data } = await axios.post('/api/session/dbacct', sessionData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      //console.log('the response')
      //console.log(data)
      //we got a provision error
      $("#console").val(JSON.stringify(data));
      if (data.hasOwnProperty('metadata')) {
        let msg = (JSON.stringify(data.metadata.reason.includes("is not a valid username")) ? 'Please choose a different username.' : JSON.stringify(data.metadata.reason));
        toast.error(msg, {
          position: "top-center",
          autoClose: 12500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        let error = msg
        var error1 = {
          error
        }
        return error1
      } else {

        var result = data
        return result
      }
    } catch (error) {
      //console.log(error)
      toast.error(error, {
        position: "top-center",
        autoClose: 12500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }



  //Start DB Provision
  const dbAccountPermsSessionCall = async (sessionData, token) => {
    //console.log('start db account provision')
    //console.log(sessionData)
    //console.log(sessionData)

    try {
      const { data } = await axios.post('/api/session/dbperms', sessionData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      //console.log('the response')
      //console.log(data)
      //we got a provision error
      $("#console").val(JSON.stringify(data));
      if (data.hasOwnProperty('metadata')) {
        let msg = (JSON.stringify(data.metadata.reason.includes("is not a valid username")) ? 'Please choose a different username.' : JSON.stringify(data.metadata.reason));
        toast.error(msg, {
          position: "top-center",
          autoClose: 12500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        let error = msg
        var error1 = {
          error
        }
        return error1
      }

      if (data.hasOwnProperty('metadata')) {
        let msg = (JSON.stringify(data.metadata.reason.includes("is not a valid username")) ? 'Please choose a different username.' : JSON.stringify(data.metadata.reason));
        toast.error(msg, {
          position: "top-center",
          autoClose: 12500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        let error = msg
        var error1 = {
          error
        }
        return error1
      }
      
      
      
      // else {

        var result = data
        return result
      // }
    } catch (error) {
      //console.log(error)
      toast.error(error, {
        position: "top-center",
        autoClose: 12500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  //Start DB Provision
  const dbHostAllowSessionCall = async (sessionData, token) => {


    try {
      const { data } = await axios.post('/api/session/allow', sessionData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      //console.log('the response')
      //console.log(data)
      //we got a provision error
      $("#console").val(JSON.stringify(data));
      if (data.hasOwnProperty('metadata')) {
        let msg = (JSON.stringify(data.metadata.reason.includes("is not a valid username")) ? 'Please choose a different username.' : JSON.stringify(data.metadata.reason));
        toast.error(msg, {
          position: "top-center",
          autoClose: 12500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        let error = msg
        var error1 = {
          error
        }
        return error1
      } else {
 
        var result = data
        return result
      }
    } catch (error) {
      //console.log(error)
      toast.error(error, {
        position: "top-center",
        autoClose: 12500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }


  //Start DB Provision
  const wpFilesSessionCall = async (sessionData, token) => {
    //console.log('start db account provision')
    //console.log(sessionData)
    //console.log(sessionData)

    try {
      const { data } = await axios.post('/api/session/wpfiles', sessionData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      //console.log('the response')
      //console.log(data)
      //we got a provision error
      $("#console").val(JSON.stringify(data));
      if (data.hasOwnProperty('metadata')) {

        let error = "some error"
        var error1 = {
          error
        }
        return error1
      } else {

        var result = data
        return result
      }
    } catch (error) {
      //console.log(error)
      toast.error(error, {
        position: "top-center",
        autoClose: 12500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    }

  }


  //Start DB Provision
  const wpDatabaseSessionCall = async (sessionData, token) => {
    //console.log('start db account provision')
    //console.log(sessionData)

    try {
      const { data } = await axios.post('/api/session/wpdb', sessionData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      //console.log('the response')
      //console.log(data)
      //we got a provision error
      $("#console").val(JSON.stringify(data));
      if (data.hasOwnProperty('metadata')) {

        let error = "some error"
        var error1 = {
          error
        }

        return error1
      } else {

        var result = data
        return result
      }
    } catch (error) {
      //console.log(error)
      toast.error(error, {
        position: "top-center",
        autoClose: 12500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }


  const createSessionCall = async (sessionData, suffix, token) => {
    var hostName = sessionData.host
    try {
      let cleanObj = {}
      for (let key in sessionData) {
        if (key == "host") {
          cleanObj[key] = sessionData[key] + "." + suffix
        } else {
          cleanObj[key] = sessionData[key]
        }
      }
      sessionData.host = sessionData.host + "." + suffix
      const { data } = await axios.post('/api/session/cpanel', cleanObj, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      //console.log(data)
      
      if (data.hasOwnProperty('metadata')) {
        sessionData.host = hostName
        let msg = (JSON.stringify(data.metadata.reason.includes("is not a valid username")) ? 'Please pick a different username.' : JSON.stringify(data.metadata.reason));
        let error = msg
        toast.error(msg, {
          position: "top-center",
          autoClose: 12500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        var error1 = {
          error
        }
        return error1

      } else {

        var result = data
        return result
      }
    } catch (error) {
      sessionData.host = hostName
      //console.log('oooooooooooo')
      if(error.response.data.message == 'User does not exist'){
        dispatch({type: "LOGOUT"})
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 12500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      toast.error(error, {
        position: "top-center",
        autoClose: 12500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log('submitted!');
    //console.log(state);
    setLoading(true)
    setSpin(true)
    setShowImage(true)
    // const idToast = toast.loading("Please wait...")

    try {
      for (let key in state) {
        if (state[key] === '') {
          setLoading(false)
          setSpin(false)
          // toast.update(idToast, { render: `You must provide the ${key}`, type: "error", isLoading: false });
          setError(`You must provide the ${key}`)
          return
        }
        if (state.username === 'test') {
          setLoading(false)
          setSpin(false)
          // toast.update(idToast, { render: "Username cannot be Test", type: "error", isLoading: false });
          setError(`Username cannot be Test`)
          return
        }
        if (state.username.length < 8) {
          setLoading(false)
          setSpin(false)
          // toast.update(idToast, { render: `Username needs to be at least 8 characters long`, type: "error", isLoading: false });
          setError(`Username needs to be at least 8 characters long`)
          return
        }
      }

      setError('');
      //console.log(state);
      const response_create = await createSessionCall(state, whmData.suffix, user.token)
      $("#provCPanelStatus").parent().closest('tr').css('display', 'block');
      //console.log(response_create)
      // if(response_create.message == 'User does not exist'){
      //   //console.log('que haceis aca papi')
      // }
      
      if (response_create.error) {
        setLoading(false)
        setSpin(false)
        // toast.update(idToast, { render: `${response_create.error}`, type: "error", isLoading: false });
        //console.log('there was an error 305')
        $("#provSaveStatus").html(icons.fail);
        $("#provSaveStatus").parent().closest('tr').css('display', 'block');
        $("#provCPanelStatus").html(icons.fail);


      } else {
        $("#provCPanelStatus").html(icons.success);
        $("#provDBStatus").parent().closest('tr').css('display', 'block');
        $("#provMainStatus").parent().closest('tr').css('display', 'block');
        $("#provMainHead").css('display', 'block');



        //Start DB Provision
        //console.log(response_create)
        const response_db = await dbSessionCall(response_create, user.token)
        if (!response_db) {
          // toast.update(idToast, { render: `Database provision failed`, type: "error", isLoading: false });
          //console.log('there wasan error 307')
          setLoading(false)
          setSpin(false)
          $("#provSaveStatus").html(icons.fail);
          $("#provDBStatus").html(icons.fail);
 

        } else {
          $("#provDBStatus").html(icons.success);
          $("#provDBUserStatus").parent().closest('tr').css('display', 'block');

          const response_dbacct = await dbAccountSessionCall(response_create, user.token)
          if (response_dbacct.error) {
            setLoading(false)
            setSpin(false)
            // toast.update(idToast, { render: `${response_dbacct.error}`, type: "error", isLoading: false });
            //console.log('there was an error 311')
            $("#provSaveStatus").html(icons.fail);
            $("#provDBUserStatus").html(icons.fail);
            $("#provSaveStatus").parent().closest('tr').css('display', 'block');
    

          } else {
            //console.log('success!')
            $("#setPerms").parent().closest('tr').css('display', 'block');
            $("#provDBUserStatus").html(icons.success);
            $("#provDBPermStatus").parent().closest('tr').css('display', 'block');
            //console.log("response_dbacct:", response_dbacct);

            let saveData = {
              host: response_create.host,
              port: response_create.port,
              hostname: response_create.hostname,
              password: response_create.password,
              username: response_create.username,
              email: response_create.email,
              database_username: response_dbacct.database_username,
              database_password: response_dbacct.database_password,
              database: response_dbacct.database
            }

            const response_dbacctperms = await dbAccountPermsSessionCall(saveData, user.token)
            if (response_dbacctperms.error) {
              setLoading(false)
              //console.log('there was an error 311')
              $("#provSaveStatus").html(icons.fail);
              $("#provDBUserStatus").html(icons.fail);
              $("#provSaveStatus").parent().closest('tr').css('display', 'block');

            } else {
              //console.log('success!')
              $("#provDBPermStatus").html(icons.success);
              $("#provSaveStatus").parent().closest('tr').css('display', 'block');

              $("#provDBPermStatus").html(icons.success);
              $("#provWPStatus").parent().closest('tr').css('display', 'block');
              $("#provWPFileStatus").parent().closest('tr').css('display', 'block');

                const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));
                console.log('wait start')
                await waitFor(60000);
                const response_save = await wpFilesSessionCall(saveData, user.token)
                if (response_save.error) {
                  setLoading(false)
                  setSpin(false)
                  $("#provSaveStatus").html(icons.fail);
                  $("#provWPFileStatus").html(icons.fail);
                  $("#provSaveStatus").html(icons.fail);
                  $("#provWPFileStatus").html(icons.fail);
     
                }
                else {
                  
                  setFinish(true)
  
                  setReloadSessions(true)
                  $("#provWPFileStatus").html(icons.success);
                  $("#provWPDBStatus").parent().closest('tr').css('display', 'block');
       

                  const response_save = await wpDatabaseSessionCall(saveData, user.token)
                  if (response_save.error) {
                    setLoading(false)
                    setSpin(false)
                    //console.log('there was an error 311')
                    $("#provSaveStatus").html(icons.fail);
                    $("#provWPDBStatus").html(icons.fail);

                  } else {
                    //console.log('success!')
                    $("#provWPDBStatus").html(icons.success);
                    setReloadSessions(true)
                    setSpin(false)
                    // $("#provSaveStatus").html(icons.success);

                    /*const response_save = await saveSessionCall(saveData, user.token)
                    if (response_save.error) {
                      //console.log('there was an error 311')
                      $("#provSaveStatus").html(icons.fail);
                    } else {
                      //console.log('success!')
                    }*/
                  }
              }
            }
          }
        }
      }

    } catch (e) {
      //console.log(e)
    }

    //window.location.reload(false);
  };

  const handleInput = e => {
    const inputName = e.currentTarget.name;
    const value = e.currentTarget.value;

    setState(prev => ({ ...prev, [inputName]: value }));
  };

  const [loading, setLoading] = useState(false)

  const Box = styled.div`
    display:flex;
    align-items: center;
    gap: 15px;
  `

  return (

    <ModalWrapper>
    

      <StyledForm onSubmit={handleSubmit}>
        <label htmlFor="domain">Domain Name </label>
        <label htmlFor="domain">{loading ? <> ({state.host}) </> : <> ({state.host}.{whmData.suffix}) </> }</label>
        <StyledInput
          type="text"
          name="host"
          value={state.host}
          onChange={handleInput}
        />

        <label htmlFor="username">cPanel Username</label>
        <StyledInput
          type="text"
          name="username"
          value={state.username}
          onChange={handleInput}
        />
        <label htmlFor="email">cPanel Email</label>
        <StyledInput
          type="text"
          name="email"
          value={state.email}
          onChange={handleInput}
        />


        {
          /* <label htmlFor="email">FTP password</label>
         <StyledInput
            type="password"
            name="password"
            value={state.password}
            onChange={handleInput}
          /> 
          <Select >
            <option value="" hidden>
              Select Previous Session
            </option>
          </Select> */}

        {error && (
          <StyledError>
            <p>{error}</p>
          </StyledError>
        )}
        <StyledButton type="submit" disabled={loading}>{loading ? 'Loading...' : 'Submit'}</StyledButton>
      </StyledForm>
      <StyledFormWrapper>
        {/* <ModalImg visibility={showImage} src={'http://hitbridge.com/wp-content/uploads/2019/01/hb-transparent.png'} alt='camera' /> */}
        <ModalImg visibility={showImage} src={logo} alt='camera' />
        <div>

          <Box>
            <h2 id="provMainHead" style={{ display: 'none' }}>  </h2>
            {spin ? <> Status <Spinner /> </> : ''}
          </Box>

          <table style={{ width: '100%' }}>
            <tbody>
              <tr style={{ display: 'none' }}>
                <th id="provMainStatus" colSpan='2'>Provisioning</th>
              </tr>
              <tr style={{ display: 'none' }}>
                <th>cPanel Account</th>
                <td id="provCPanelStatus"></td>
              </tr>
              <tr style={{ display: 'none' }}>
                <th>Database</th>
                <td id="provDBStatus"></td>
              </tr>
              <tr style={{ display: 'none' }}>
                <th>Database User</th>
                <td id="provDBUserStatus"></td>
              </tr>
              <tr style={{ display: 'none' }}>
                <th id="setPerms" colSpan='2'>Set Permissions</th>
              </tr>
              <tr style={{ display: 'none' }}>
                <th>Database</th>
                <td id="provDBPermStatus"></td>
              </tr>
              <tr style={{ display: 'none'}}>
                <th id="provWPStatus" colSpan='2'>Setup WordPress</th>
              </tr>
              <tr style={{ display: 'none' }}>
                <th>Copy Files</th>
                <td id="provWPFileStatus"></td>
              </tr>
              <tr style={{ display: 'none' }}>
                <th>Copy Database</th>
                <td id="provWPDBStatus"></td>
              </tr>
              <tr style={{ display: 'none'}}>
                <hr />
                <th>Once file copying is complete, you will receive an email with the details to login to your site.</th>
                <td id="provSaveStatus"></td>
              </tr>

              {finish && (
                    <tr >
                      <th><Title>You may close this modal now</Title></th>
                    </tr>
              )}
              <tr style={{ display: 'none' }}>
                <th colSpan='2'>
                  <textarea rows="50" cols="150" style={{ width: '100%', height: '100px' }} id="console"></textarea>
                </th>
              </tr>
            </tbody>
          </table>

        </div>
      </StyledFormWrapper>
    </ModalWrapper>
  )
}

export default SubmitBusinessForm
