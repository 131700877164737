import React from 'react'
import styled from "styled-components";
import bgImg from "../assets/bg-2.jpg"
import Sidebar from '../components/LoginPage/Sidebar'


const Container = styled.div`
  background: #eefcff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
const Wrapper = styled.div`
  /* background-image: url(${bgImg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  background-color:#002F5C;
  width: 100%;
  height: 100%;
  justify-content:center;
  display: flex;
`;

function Login() {
  return (
    <Container>
      <Wrapper>
        <Sidebar />
      </Wrapper>
    </Container>
  )
}

export default Login
