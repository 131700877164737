import React, {useState,useEffect, useContext} from 'react'

import MotionHoc from "./MotionHoc";
import { Link, Redirect ,useHistory} from "react-router-dom";
import UsersList from '../components/usersList/UsersList';
import styled from 'styled-components';
import { Modal } from '../components/ModalCreate/Modal';
import CreateUserForm from '../components/submitUserForm/SubmitUserForm';
import axios from 'axios'
import {Context} from '../context/Context'


const Container = styled.div`
display: flex;
height:97vh;
/* backdrop-filter: blur(35px);
  background-color: rgba(255, 255, 255, 0.8); */
  /* background-color: #09090c; */
  // backdrop-filter: blur(3px);
    // background-color: #ffffff13;
    background-color: white;

  /* background: linear-gradient(to bottom right, white 0%, #e6e4ff 70%); */
  /* background: linear-gradient(to bottom right, white 0%, #e6e4ff 70%); */
  border-radius: 2em;
  margin: 1rem 2rem 1rem 6rem;
  // box-shadow: 0 1px 3px rgba(0,0,0,0.9);
  `
  const Wrapper = styled.div`
  align-items: center;
    // width: 90%;
    width: 100%;
    // background: linear-gradient(to bottom right, white  right, #e6e4ff 70%);
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
    /* margin: 1rem 8rem 1rem 4rem; */
    margin: 1rem 4rem 1rem 4rem;
  
  `
const SectionOne = styled.div`
  display: flex;
  /* justify-content: space-between; */
  /* background-color: red; */
  /* height: 40%; */
  height: 100%;
  flex-direction: column ;
  gap: 2rem;
  /* width:100%; */
  width: 50%;
`
const SectionTwo = styled.div`
  display: flex;
  flex-direction: column ;
  /* background-color: blue; */
  gap: 2rem;
  width: 100%;
  height:100%;
`

const Expenses = styled.div`
  color:white;
`
const ExpenseInfo = styled.div`
      display: flex;
      width: inherit;
      justify-content: space-between;
      margin-bottom: 1rem;
      color: black;
`
const ExpensesContainer = styled.div`
      max-width: 350px;
      /* overflow-x: scroll; */
      display: flex;
      justify-content: center;
      gap: 10px;
`
const ButtonBox = styled.div`
      /* max-width: 350px; */
      /* overflow-x: scroll; */
      display: flex;
      justify-content: center;
      gap: 10px;
`

const Expense = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.3rem;
        img {
          height: 4rem;
          max-height: 4rem;
          overflow: hidden;
          border-radius: 2rem;
          transition: 0.4s ease-in-out;
          &:hover {
            transform: scale(1.1);
          }
        }
        h6 {
          color: #f94144;
        }
`

const Button = styled.button`
  background-color: var(--black);
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin: 0.5rem 0 0 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;

        img {
 
          max-height: 3rem;
          overflow: hidden;
          border-radius: 2rem;
          transition: 0.4s ease-in-out;
          &:hover {
            transform: scale(1.1);
          }
        }

`;
const DashboadUsersComponent = () => {
  const { user } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  let history = useHistory();

  if(user.user.role === 'user'){
    history.push('/')
  }
  const openModal = () => {
    setShowModal(prev => !prev);
  };
  

  
  
  const [reloadUsers, setReloadUsers] = useState(false);
  const [users, setUsers] = useState(null)

  useEffect(() => {
    const getUsers = async () => {
      try {
          const {data} = await axios.get('/api/users',{   
            headers: {
            'Authorization': `Bearer ${user.token}` 
          }})
          //console.log(data.users)
 
          setUsers(data.users)
      
          
          setReloadUsers(false)
      } catch (error) {
          //console.log(error)
          // setSessions({...values})
      }
  };

  getUsers();

  }, [reloadUsers]);


  return (
    <>
    {/* <Modal showModal={showModal} setShowModal={setShowModal} /> */}
    <Modal showModal={showModal} setShowModal={setShowModal}>
      <CreateUserForm setReloadUsers={setReloadUsers}/>
    </Modal>

    <Container>
      <Wrapper>
        <ExpenseInfo>
            <h4>Current Users</h4>
            <ButtonBox>
            <Button onClick={openModal} >+</Button>
            </ButtonBox>
          </ExpenseInfo>
        <UsersList users={users} setReloadUsers={setReloadUsers}/>
      </Wrapper>
    </Container>
    </>
  )
}

const DashboardUsers = MotionHoc(DashboadUsersComponent);

export default DashboardUsers;