import React from 'react'
import {Visibility} from '@material-ui/icons';
import "./widgetSm.css"

export default function WidgetSm() {
    return (
        <div className="widgetSm">
            <span className="widgetSmTitle">New Join Members</span>
            <ul className="widgetSmList">
                <li className="widgetSmListItem">
                    <img src="https://pley.today/__export/1587894637881/sites/mui/img/2020/04/26/actress-women-alexandra-daddario-blue-eyes-wallpaper-preview_jpg_556145692.jpg_1381558010.jpg" alt="" className="widgetSmImg" />
                    <div className="widgetSmUser">
                        <span className="widgetSmUsername">Alexandra Daddario</span>
                        <span className="widgetSmUserTitle">Actress</span>
                    </div>
                    <button className="widgetSmButton">
                        <Visibility className="widgetSmIcon"/>
                        Display
                    </button>
                </li>
                <li className="widgetSmListItem">
                    <img src="https://pley.today/__export/1587894637881/sites/mui/img/2020/04/26/actress-women-alexandra-daddario-blue-eyes-wallpaper-preview_jpg_556145692.jpg_1381558010.jpg" alt="" className="widgetSmImg" />
                    <div className="widgetSmUser">
                        <span className="widgetSmUsername">Alexandra Daddario</span>
                        <span className="widgetSmUserTitle">Actress</span>
                    </div>
                    <button className="widgetSmButton">
                        <Visibility className="widgetSmIcon"/>
                        Display
                    </button>
                </li>
                <li className="widgetSmListItem">
                    <img src="https://pley.today/__export/1587894637881/sites/mui/img/2020/04/26/actress-women-alexandra-daddario-blue-eyes-wallpaper-preview_jpg_556145692.jpg_1381558010.jpg" alt="" className="widgetSmImg" />
                    <div className="widgetSmUser">
                        <span className="widgetSmUsername">Alexandra Daddario</span>
                        <span className="widgetSmUserTitle">Actress</span>
                    </div>
                    <button className="widgetSmButton">
                        <Visibility className="widgetSmIcon"/>
                        Display
                    </button>
                </li>
            </ul>
        </div>
    )
}
