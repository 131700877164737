import axios from "axios";
import { useContext, useRef,useEffect } from "react";
import { Context } from "../../context/Context";
import styled from "styled-components";
// import logo from "../../assets/bg-1.png";
import logo from "../../assets/logoHB.png";
import { Link, Redirect ,useHistory} from "react-router-dom";
// import logo from "../../assets/logo.svg";
import Input from "../Input";
import {  toast } from 'react-toastify';

const Sidebar = () => {
  const userRef = useRef();
  const passwordRef = useRef();
  let history = useHistory();

  const { user, dispatch, isFetching } = useContext(Context);


  // useEffect(() => {
  // if(user){
  //   history.push('/')
  // }
  // }, [user])

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log('a')
    dispatch({ type: "LOGIN_START" });
    try {
      // const res = await axios.post(`${process.env.REACT_APP_API}/signin`, {
        // const res = await axios.post("http://localhost:8000/api/signin", {
        const res = await axios.post("/api/signin", {
        email: userRef.current.value,
        password: passwordRef.current.value,
      });
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      // history.push('/')
      // //console.log('a')
      toast.success(' Success!', {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    } catch (error) {
      //console.log(error.response)
      toast.success(` ${error.response.data.error}`, {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      dispatch({ type: "LOGIN_FAILURE" });
    }
  };
// //console.log(user)
// //console.log(isFetching)
  // if(user){
  //   return (
  //     <>
  //       <Redirect to="/"/>
  //     </>
  //   )
  // }
  return (
    <Container>
      <LogoWrapper>
        <img src={logo} alt="" />
        {/* <img src={'http://hitbridge.com/wp-content/uploads/2019/01/hb-transparent.png'} alt="" /> */}
        <h3>
        HUI
        </h3>
      </LogoWrapper>
      <Form onSubmit={handleSubmit}>
        <h3>Signin</h3>
        {/* <Input placeholder="Nombre Completo" /> */}
        <StyledInput type="email" placeholder="Email" ref={userRef}/>
        <StyledInput type="password" placeholder="Password" ref={passwordRef}/>
        {/* <Input type="password" placeholder="Confirmar contraseña" /> */}
        <button type="submit" disabled={isFetching}>Log in</button>
      </Form>
      <div>
        {/* <Terms>
          Registrandote, Aceptas las politicas de privacidad <br /> y Terminos y 
          condiciones
        </Terms> */}
        <h4>
          {/* Dont have an account? <LinkS to="/register">Signup</LinkS> */}
        </h4>
      </div>
    </Container>
  );
};

const StyledInput = styled.input`
  width: 80%;
  max-width: 350px;
  min-width: 250px;
  height: 40px;
  border: none;
  margin: 0.5rem 0;
  // background-color: #f5f5f5;
  background-color: rgb(209, 207, 207);
  
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease-in;
  &:hover {
    transform: translateY(-3px);
  }
`;

const Terms = styled.p`
  padding: 0 1rem;
  text-align: center;
  font-size: 10px;
  color: #808080;
  font-weight: 300;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    color: #666666;
    margin-bottom: 2rem;
  }
  button {
    width: 75%;
    max-width: 350px;
    min-width: 250px;
    height: 40px;
    border: none;
    margin: 1rem 0;
    box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    /* background-color: #70edb9; */
    background-color: #031b56;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in;
    &:hover {
      transform: translateY(-3px);
    }
  }
`;

const LogoWrapper = styled.div`
  img {
    height: 6rem;
  }
  h3 {
    color: #031b56;
    
    text-align: center;
    font-size: 22px;
  }
  span {
    /* color: #cc2533; */
    color: #cc2533;
    font-weight: 300;
    font-weight: bold;
    font-size: 18px;
  }
`;

const Container = styled.div`
  margin-top:10px;
  margin-bottom:10px;
  min-width: 400px;
  backdrop-filter: blur(35px);
  // background-color: rgba(255, 255, 255, 0.8);
  background-color: white;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 2rem;
  @media (max-width: 900px) {
    width: 100vw;
    position: absolute;
    padding: 0;
  }
  h4 {
    color: black;
    font-weight: bold;
    font-size: 13px;
    margin-top: 2rem;
    span {
      /* color: #ff8d8d; */
      color: #cc2533;
      cursor: pointer;
    }
  }
`;
const LinkS = styled(Link)`
      /* color: #ff8d8d; */
      color: 	#E1AD01;
      cursor: pointer;
`

export default Sidebar;