import React from 'react'
import "./widgetLg.css"

export default function WidgetLg() {

    const Button = ({type}) => {
        return <button className={"widgetLgButton " + type}>{type}</button>
    }
    return (
        <div className="widgetLg">
            <h3 className="widgetLgTitle">Latest transactions</h3>
            <table className="widgetLgTable">
                <tr className="widgetLgTr">
                    <th className="widgetLgTh">Customer</th>
                    <th className="widgetLgTh">Date</th>
                    <th className="widgetLgTh">Amount</th>
                    <th className="widgetLgTh">Status</th>
                </tr>
                <tr className="widgetLgTr">
                  <td className="widgetLgUser">
                        <img src="https://pley.today/__export/1587894637881/sites/mui/img/2020/04/26/actress-women-alexandra-daddario-blue-eyes-wallpaper-preview_jpg_556145692.jpg_1381558010.jpg" alt="" className="widgetLgImg" />
                        <span className="widgetLgName">Alexandra Daddario</span>
                  </td>
                  <td className="widgetLgDate">2 Jun 2021</td>
                  <td className="widgetLgAmount">$420.00</td>
                  <td className="widgetLgStatus">
                    <Button type="Approved"/>
                  </td>
                </tr>
                <tr className="widgetLgTr">
                  <td className="widgetLgUser">
                        <img src="https://pley.today/__export/1587894637881/sites/mui/img/2020/04/26/actress-women-alexandra-daddario-blue-eyes-wallpaper-preview_jpg_556145692.jpg_1381558010.jpg" alt="" className="widgetLgImg" />
                        <span className="widgetLgName">Alexandra Daddario</span>
                  </td>
                  <td className="widgetLgDate">2 Jun 2021</td>
                  <td className="widgetLgAmount">$420.00</td>
                  <td className="widgetLgStatus">
                    <Button type="Declined"/>
                  </td>
                </tr>
                <tr className="widgetLgTr">
                  <td className="widgetLgUser">
                        <img src="https://pley.today/__export/1587894637881/sites/mui/img/2020/04/26/actress-women-alexandra-daddario-blue-eyes-wallpaper-preview_jpg_556145692.jpg_1381558010.jpg" alt="" className="widgetLgImg" />
                        <span className="widgetLgName">Alexandra Daddario</span>
                  </td>
                  <td className="widgetLgDate">2 Jun 2021</td>
                  <td className="widgetLgAmount">$420.00</td>
                  <td className="widgetLgStatus">
                    <Button type="Pending"/>
                  </td>
                </tr>
                <tr className="widgetLgTr">
                  <td className="widgetLgUser">
                        <img src="https://pley.today/__export/1587894637881/sites/mui/img/2020/04/26/actress-women-alexandra-daddario-blue-eyes-wallpaper-preview_jpg_556145692.jpg_1381558010.jpg" alt="" className="widgetLgImg" />
                        <span className="widgetLgName">Alexandra Daddario</span>
                  </td>
                  <td className="widgetLgDate">2 Jun 2021</td>
                  <td className="widgetLgAmount">$420.00</td>
                  <td className="widgetLgStatus">
                    <Button type="Approved"/>
                  </td>
                </tr>
            </table>
        </div>
    )
}
