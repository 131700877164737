import { useContext } from "react";
import Login from "./pages/Login";
import Register from "./pages/Register";
import "./index.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom"
import { Context,ContextProvider } from "./context/Context";
import Dashboard from "./pages/Dashboard";
import UsersPanel from "./pages/UsersPanel";
import Session from "./pages/session/Session";
import User from "./pages/user/User";
import Me from "./pages/me/Me";
import { AnimatePresence } from "framer-motion";
import Sidebar from "./components/Sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
const App = () => {
  const location = useLocation();
  const { user } = useContext(Context);
  //console.log(user)
  // const user  = true
  return (

      <>
        <ToastContainer />
        {user ? <Sidebar/>: <Login/>}
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
              <Route exact path="/">
              {/* {user ? <Dashboard/> : <Redirect to="/login"/>} */}
              {user ? <Dashboard/> : <Login/>}
              </Route>
              <Route exact path="/users-panel">
              {/* {user ? <Dashboard/> : <Redirect to="/login"/>} */}
              {user ? <UsersPanel/> : <Login/>}
              </Route>
              <Route exact path="/session/:sessionId">
              {user ? <Session/> : <Redirect to="/login"/>}
              </Route>
              <Route exact path="/user/:userId">
              {user ? <User/> : <Redirect to="/login"/>}
              </Route>
              <Route exact path="/me">
              {user ? <Me/> : <Redirect to="/login"/>}
              </Route>
              {/* <Route exact path="/register">
       
                {user ? <Dashboard/> : <Register/> }
              </Route> */}
              <Route exact path="/login">
              {user ? <Dashboard/> : <Login/>}
              </Route>
            </Switch>
          </AnimatePresence>
      </>

  )
};

export default App;