import React, { useState, useContext } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import Input from '../Input';
import axios from "axios";
import {  toast } from 'react-toastify';
import {Context} from '../../context/Context'
const sharedStyles = css`
  background-color: #eee;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;

const StyledFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 20px;
`;

const StyledForm = styled.form`
  width: 100%;
  // max-width: 700px;
  padding: 40px;
  /* background-color: #fff; */
  border-radius: 10px;
  box-sizing: border-box;
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: column;
  input{
    background-color: #d1cfcf;
  }
  >button{
    margin-top: 20px;
  }
`;

const StyledInput = styled.input`
  width: 80%;
  max-width: 350px;
  min-width: 250px;
  height: 40px;
  border: none;
  margin: 0.5rem 0;
  background-color: #f5f5f5;
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease-in;
  &:hover {
    transform: translateY(-3px);
  }
`;

const StyledTextArea = styled.textarea`
  background-color: #eee;
  width: 100%;
  min-height: 100px;
  resize: none;
  ${sharedStyles}
`;
const StyledButton = styled.button`
  display: block;
  // background-color: #f7797d;
  background-color: #031b56 !important;
  color: #fff;
  font-size: 0.9rem;
  border: 0;
  border-radius: 5px;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border-box;
`;

const StyledFieldset = styled.fieldset`
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 0;
  legend {
    padding: 0 10px;
  }
  label {
    padding-right: 20px;
  }
  input {
    margin-right: 10px;
  }
`;

const StyledError = styled.div`
  color: red;
  font-weight: 800;
  /* margin: 0 0 40px 0; */
`;

const Select = styled.select`
  width: 80%;
  max-width: 350px;
  min-width: 250px;
  height: 40px;
  border: none;
  margin: 0.5rem 0;
  background-color: #f5f5f5;
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease-in;
  &:hover {
    transform: translateY(-3px);
  }
`

const Form = styled.div`
  /* height: 250px; */
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const LeftForm = styled.div`
  /* height: 100%; */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  gap: 10px;
  @media only screen and (max-width: 480px) {
    height: 50%;
    margin-right: 0;
  }
`;


function SubmitUserForm(props) {

//console.log(props)
  const initalState = {
    username:'',
    email: '',
    password:'',
    role: ''
  };

  const { user } = useContext(Context);
  const [state, setState] = useState(initalState);
  const [error, setError] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    //console.log('submitted!');
    //console.log(state);

    for (let key in state) {
      if (state[key] === '') {
        setError(`You must provide the ${key}`)
        return
      }
    }
    setError('');
    try {
      const {data} = await axios.post("/api/add-user", state, 
      {   
        headers: {
        'Authorization': `Bearer ${user.token}` 
      }});

      toast.success('Update successful!', {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        //console.log(data)
        // window.location.reload(false);
        props.setReloadUsers(true);

    } catch (err) {
      // console.log(err.response.data)
      toast.error(`${err.response.data}`, {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      setError(true);
    }
    //console.log("Succeeded!!!")
  };

  const handleInput = e => {
    //console.log('aaaaaaaa')
    const inputName = e.currentTarget.name;
    const value = e.currentTarget.value;

    setState(prev => ({ ...prev, [inputName]: value }));
  };
  //console.log(state)
  return (
  <>

        <StyledForm onSubmit={handleSubmit}>
          <Form>
            <LeftForm>
              <label htmlFor="name">Username</label>
            <StyledInput
              type="text"
              name="username"
              value={state.username}
              onChange={handleInput}
            />

            <label htmlFor="email">Email</label>
          <StyledInput
              type="email"
              name="email"
              value={state.email}
              onChange={handleInput}
            />
              
            <label htmlFor="email">Password</label>
          <StyledInput
              type="password"
              name="password"
              value={state.password}
              onChange={handleInput}
            /> 
            <label htmlFor="email">Role</label>
            <Select  name="role" value={state.role} onChange={handleInput}>
              <option value="" hidden>
                            Select role
              </option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </Select>
            </LeftForm>

          </Form>


          {/* <Select >
            <option value="" hidden>
              Select Previous Session
            </option>
          </Select> */}

          {error && (
            <StyledError>
              <p>{error}</p>
            </StyledError>
          )}
          <StyledButton type="submit">Submit</StyledButton>
        </StyledForm>
      </>
  )
}

export default SubmitUserForm
