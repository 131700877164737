
   export  const userData = [
        {
          name: 'Jan',
          "Active User": 4000,

        },
        {
          name: 'Feb',
          "Active User": 3000,

        },
        {
          name: 'Mar',
          "Active User": 2000,

        },
        {
          name: 'Apr',
          "Active User": 2780,
  
        },
        {
          name: 'May',
          "Active User": 1890,

        },
        {
          name: 'Jul',
          "Active User": 2390,
 
        },
        {
          name: 'Agu',
          "Active User": 3490,
   
        },
        {
            name: 'Sep',
            "Active User": 3490,
     
          },
          {
            name: 'Oct',
            "Active User": 5490,
     
          },
          {
            name: 'Nov',
            "Active User": 7490,
     
          },
          {
            name: 'Dec',
            "Active User": 1490,
     
          },
      ];

      export const userRows = [
        { 
            id: 1, 
            domainName: 'Alexandra Daddario', 
            avatar:"https://hips.hearstapps.com/es.h-cdn.co/fotoes/images/noticias-cine/alexandra-daddario-ficha-por-los-vigilantes-de-la-playa/84435126-1-esl-ES/Alexandra-Daddario-ficha-por-Los-vigilantes-de-la-playa.jpg",
            domainUsername: "alex@gmail.com",
            status: "active",
            transaction:"$120.00",
        },
        // { 
        //     id: 2, 
        //     username: 'Alexandra Daddario', 
        //     avatar:"https://hips.hearstapps.com/es.h-cdn.co/fotoes/images/noticias-cine/alexandra-daddario-ficha-por-los-vigilantes-de-la-playa/84435126-1-esl-ES/Alexandra-Daddario-ficha-por-Los-vigilantes-de-la-playa.jpg",
        //     email: "alex@gmail.com",
        //     status: "active",
        //     transaction:"$120.00",
        // },
        // { 
        //     id: 3, 
        //     username: 'Alexandra Daddario', 
        //     avatar:"https://hips.hearstapps.com/es.h-cdn.co/fotoes/images/noticias-cine/alexandra-daddario-ficha-por-los-vigilantes-de-la-playa/84435126-1-esl-ES/Alexandra-Daddario-ficha-por-Los-vigilantes-de-la-playa.jpg",
        //     email: "alex@gmail.com",
        //     status: "active",
        //     transaction:"$120.00",
        // },
        // { 
        //     id: 4, 
        //     username: 'Alexandra Daddario', 
        //     avatar:"https://hips.hearstapps.com/es.h-cdn.co/fotoes/images/noticias-cine/alexandra-daddario-ficha-por-los-vigilantes-de-la-playa/84435126-1-esl-ES/Alexandra-Daddario-ficha-por-Los-vigilantes-de-la-playa.jpg",
        //     email: "alex@gmail.com",
        //     status: "active",
        //     transaction:"$120.00",
        // },
        // { 
        //     id: 5, 
        //     username: 'Alexandra Daddario', 
        //     avatar:"https://hips.hearstapps.com/es.h-cdn.co/fotoes/images/noticias-cine/alexandra-daddario-ficha-por-los-vigilantes-de-la-playa/84435126-1-esl-ES/Alexandra-Daddario-ficha-por-Los-vigilantes-de-la-playa.jpg",
        //     email: "alex@gmail.com",
        //     status: "active",
        //     transaction:"$120.00",
        // },
        // { 
        //     id: 6, 
        //     username: 'Alexandra Daddario', 
        //     avatar:"https://hips.hearstapps.com/es.h-cdn.co/fotoes/images/noticias-cine/alexandra-daddario-ficha-por-los-vigilantes-de-la-playa/84435126-1-esl-ES/Alexandra-Daddario-ficha-por-Los-vigilantes-de-la-playa.jpg",
        //     email: "alex@gmail.com",
        //     status: "active",
        //     transaction:"$120.00",
        // },

      ];