import React, { useState, useContext } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import Input from '../Input';
import axios from "axios";
import {  toast } from 'react-toastify';
import {Context} from '../../context/Context'
const sharedStyles = css`
  background-color: #eee;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;

const StyledFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 20px;
`;

const StyledForm = styled.form`
  width: 100%;
  // max-width: 700px;
  padding: 40px;
  /* background-color: #fff; */
  border-radius: 10px;
  box-sizing: border-box;
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: column;
  input{
    background-color: #d1cfcf;
  }
  >button{
    margin-top: 20px;
  }
`;

const StyledInput = styled.input`
  width: 80%;
  max-width: 350px;
  min-width: 250px;
  height: 40px;
  border: none;
  margin: 0.5rem 0;
  background-color: #f5f5f5;
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease-in;
  &:hover {
    transform: translateY(-3px);
  }
`;

const StyledTextArea = styled.textarea`
  background-color: #eee;
  width: 100%;
  min-height: 100px;
  resize: none;
  ${sharedStyles}
`;
const StyledButton = styled.button`
  display: block;
  // background-color: #f7797d;
  background-color: #031b56 !important;
  color: #fff;
  font-size: 0.9rem;
  border: 0;
  border-radius: 5px;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border-box;
  
  transition: background-color 0.5s ease-in-out;
  border: ${props => (props.active ? "1px solid black" : "")};
  border-bottom: ${props => (props.active ? " 3px solid #E1AD01" : "")};
  background-color: ${props => (props.active ? "#031b56" : "var(--black)")};
  height: ${props => (props.active ? "3em" : "2.6em; top:.4em")};
`;

const StyledFieldset = styled.fieldset`
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 0;
  legend {
    padding: 0 10px;
  }
  label {
    padding-right: 20px;
  }
  input {
    margin-right: 10px;
  }
`;

const StyledError = styled.div`
  color: red;
  font-weight: 800;
  /* margin: 0 0 40px 0; */
`;

const Select = styled.select`
  width: 80%;
  max-width: 350px;
  min-width: 250px;
  height: 40px;
  border: none;
  margin: 0.5rem 0;
  background-color: #f5f5f5;
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease-in;
  &:hover {
    transform: translateY(-3px);
  }
`

const Form = styled.div`
  /* height: 250px; */
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const LeftForm = styled.div`
  /* height: 100%; */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  margin-right: 20px;
  gap: 10px;
  min-height: 260px;
  @media only screen and (max-width: 480px) {
    height: 50%;
    margin-right: 0;
  }
`;

const Content = styled.div`
  ${props => (props.active ? "" : "display:none")}
`;

const TitleBox = styled.div`
    display: flex;
    flex-wrap: wrap;
align-content: space-around;
    gap: 15px;
    margin-top: 10px;
    justify-content: center;
    margin-bottom: 25px;
`

const InputGroup = styled.div`
  display:flex;
  flex-direction: column;
`




function SubmitCredentialsForm(props) {

  // var initialState = {
  //   whmKey: '',
  //   serverUrl: '',
  //   package: '',
  //   cpUsername: '',
  //   cpPassword: '',
  //   mysqlUsername: '',
  //   mysqlPassword: '',
  //   mysqlHost: '',
  //   // mysqlName: '',
  //   mysqlName: '',
  //   suffix: '',
  //   ftpHost: '',
  //   wpPrefix: '',
    
  // };
  const { whmData, setReloadSessions } = props
  // console.log(whmData)

  // if(whmData){

  //     initialState.whmKey =  whmData.whmKey,
  //     initialState.serverUrl= whmData.serverUrl,
  //     initialState.package = whmData.package,
  //     initialState.cpUsername = whmData.cpUsername,
  //     initialState.cpPassword = whmData.cpPassword,
  //     initialState.mysqlUsername = whmData.mysqlUsername,
  //     initialState.mysqlPassword = whmData.mysqlPassword,
  //     initialState.mysqlHost = whmData.mysqlHost,
  //     // mysqlName: '',
  //     initialState.mysqlName = whmData.mysqlName,
  //     initialState.suffix = whmData.suffix,
  //     initialState.ftpHost = whmData.ftpHost,
  //     initialState.wpPrefix = whmData.wpPrefix,
      
  //   console.log('wadup')
  // } 
  
  


  const { user } = useContext(Context);
  const [state, setState] = useState(whmData);
  const [error, setError] = useState('');

  const [active, setActive] = useState(0);
  const handleClick = e => {
      const index = parseInt(e.target.id, 0);
      if (index !== active) {
          setActive(index);
      }
  };

  console.log(state)
  const handleSubmit = async e => {
    e.preventDefault();
    //console.log('submitted!');
    //console.log(state);

    for (let key in state) {
      if (state[key] === '') {
        setError(`You must provide the ${key}`)
        return
      }
    }
    setError('');
    try {
      const {data} = await axios.post("/api/whm", state, 
      {   
        headers: {
        'Authorization': `Bearer ${user.token}` 
      }});

      toast.success('Update successful!', {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        //console.log(data)
        setReloadSessions(true)
        // window.location.reload(false);

    } catch (err) {
      //console.log(err)
      toast.success(`${err}`, {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      setError(true);
    }
    //console.log("Succeeded!!!")
  };

  const handleInput = e => {
    //console.log('aaaaaaaa')
    const inputName = e.currentTarget.name;
    const value = e.currentTarget.value;

    setState(prev => ({ ...prev, [inputName]: value }));
  };
  //console.log(state)
  return (
  <>

        <StyledForm onSubmit={handleSubmit}>
        <TitleBox>
              <StyledButton type="button" onClick={handleClick} active={active === 0} id={0}>WHM Reseller Settings</StyledButton>
              <StyledButton type="button" onClick={handleClick} active={active === 1} id={1}>Template Settings</StyledButton>
          </TitleBox>
          <Content active={active === 0}>
          <Form>
            <LeftForm>
              <label htmlFor="name">WHM Key</label>
            <StyledInput
              type="text"
              name="whmKey"
              value={state.whmKey}
              onChange={handleInput}
            />

            <label htmlFor="email">Server URL</label>
          <StyledInput
              type="text"
              name="serverUrl"
              value={state.serverUrl}
              onChange={handleInput}
            />
              
            <label htmlFor="email">Package</label>
          <StyledInput
              type="text"
              name="package"
              value={state.package}
              onChange={handleInput}
            /> 
            <label htmlFor="email">Suffix domain</label>
          <StyledInput
              type="text"
              name="suffix"
              value={state.suffix}
              onChange={handleInput}
            /> 
            </LeftForm>
          </Form>

          </Content>
          <Content active={active === 1}>
          <Form>
          <LeftForm>
          <InputGroup>
            <label htmlFor="email">cPanel Username</label>
         <StyledInput
            type="text"
            name="cpUsername"
            value={state.cpUsername}
            onChange={handleInput}
          />
          </InputGroup>
            <InputGroup>
          <label htmlFor="email">cPanel password</label>
         <StyledInput
            type="password"
            name="cpPassword"
            value={state.cpPassword}
            onChange={handleInput}
          /> 
          </InputGroup>
          <InputGroup>
          <label htmlFor="email">FTP Host</label>
         <StyledInput
            type="text"
            name="ftpHost"
            value={state.ftpHost}
            onChange={handleInput}
          /> 
          </InputGroup>
            </LeftForm>

            <LeftForm>
            <InputGroup>
            <label htmlFor="email">MySQL Name</label>
         <StyledInput
            type="text"
            name="mysqlName"
            value={state.mysqlName}
            onChange={handleInput}
          /> 

          </InputGroup>
          <InputGroup>
            <label htmlFor="email">MySQL Username</label>
         <StyledInput
            type="text"
            name="mysqlUsername"
            value={state.mysqlUsername}
            onChange={handleInput}
          />
          </InputGroup>
            <InputGroup>
          <label htmlFor="email">MySQL Password</label>
         <StyledInput
            type="password"
            name="mysqlPassword"
            value={state.mysqlPassword}
            onChange={handleInput}
          /> 
          </InputGroup>
            </LeftForm>
            <LeftForm>
            <InputGroup>
            <label htmlFor="email">MySQL Host</label>
         <StyledInput
            type="text"
            name="mysqlHost"
            value={state.mysqlHost}
            onChange={handleInput}
          /> 
            </InputGroup>
            <InputGroup>
            <label htmlFor="email">MySQL Prefix</label>
         <StyledInput
            type="text"
            name="wpPrefix"
            value={state.wpPrefix}
            onChange={handleInput}
          /> 
            </InputGroup>

  
            </LeftForm>
          </Form>

          </Content>



          {/* <Select >
            <option value="" hidden>
              Select Previous Session
            </option>
          </Select> */}

          {error && (
            <StyledError>
              <p>{error}</p>
            </StyledError>
          )}
          <StyledButton type="submit">Submit</StyledButton>
        </StyledForm>
      </>
  )
}

export default SubmitCredentialsForm
