import React from "react";
import styled from "styled-components";

const Main = () => {
  return (
    <Container>
      <h1>
      Inicia tu <br />
      aventura <br />
      hoy
        
      </h1>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 65px;
    font-weight: 900;
    color: white;
    text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
    @media (max-width: 900px) {
      display: none;
    }
  }
`;

export default Main;