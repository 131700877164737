import React, {useEffect, useState, useContext} from 'react'
import {CalendarToday, LocationSearching, MailOutline, PermIdentity, PhoneAndroid, Publish} from '@material-ui/icons';
import "./user.css"
import {Link, useLocation, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import axios from "axios";
import {toast} from 'react-toastify';
import {FaBeer} from 'react-icons/fa'
import {Context} from '../../context/Context'
import {Modal} from '../../components/ModalCreate/Modal';

const Container = styled.div`
  display: flex;
  height: 99vh;
  /* backdrop-filter: blur(35px);
    background-color: rgba(255, 255, 255, 0.8); */
  /* background-color: #09090c; */
  backdrop-filter: blur(3px);
  // background-color: #ffffff13;

  /* background: linear-gradient(to bottom right, white 0%, #e6e4ff 70%); */
  /* background: linear-gradient(to bottom right, white 0%, #e6e4ff 70%); */
  border-radius: 2em;
  margin: 1rem 2rem 1rem 6rem;
  // box-shadow: 0 1px 3px rgba(0,0,0,0.9);
`
const Wrapper = styled.div`
  align-items: center;
  // width: 90%;
  width: 100%;
  background: linear-gradient(to bottom right, white right, #e6e4ff 70%);
  display: flex;
  flex-direction: column;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
  /* margin: 1rem 8rem 1rem 4rem; */
  margin: 0rem 0rem 1rem 0rem;

`

const FormContainer = styled.form`
  width: 100%;
  height: 100%;
  border-radius: 25px;
  color: black;
  background-color: var(--white);
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 15px;
  flex-direction: column;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  label {
    color: black;
  }
`;


const StyledButton = styled.button`
  display: block;
  background-color: black;
  color: #fff;
  font-size: 0.9rem;
  border: 0;
  border-radius: 5px;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background-color: var(--black);
  }

  border: ${props => (props.active ? "1px solid black" : "")};
  border-bottom: ${props => (props.active ? "none" : "")};
  background-color: ${props => (props.active ? "black" : "var(--black)")};
  height: ${props => (props.active ? "3em" : "2.6em; top:.4em")};
  transition: background-color 0.5s ease-in-out;

  ${({submit}) => submit && `
   background-color: black;

  `}

`;

const Title = styled.h1`
  margin: 30px;

  /* margin-top: 0; */
  @media only screen and (max-width: 480px) {
    margin: 20px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  gap: 15px;
  margin-top: 10px;
  justify-content: center;
  margin-bottom: 25px;
`
const TitleBoxOne = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 25px;
  align-items: center;
`
export const Content = styled.div`
  ${props => (props.active ? "" : "display:none")}
`;

const Form = styled.div`
  /* height: 250px; */

  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const LeftForm = styled.div`
  /* height: 100%; */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-right: 20px; */
  gap: 10px;
  @media only screen and (max-width: 480px) {
    height: 50%;
    margin-right: 0;
  }

  label {
    color: black;
  }
`;

const RightForm = styled.div`
  height: 100%;
  /* margin-left: 10px; */
  /* margin-right: 20px; */
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: 480px) {
    height: 50%;
  }

  label {
    color: black;
  }
`;

const StyledInput = styled.input`
  width: 200px;
  max-width: 350px;
  /* min-width: 250px; */
  height: 40px;
  border: none;
  margin: 0.5rem 0;
  // background-color: #f5f5f5;
  background-color: rgb(209, 207, 207);
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease-in;

  &:hover {
    transform: translateY(-3px);
  }

  ${({small}) => small && `
      width: 150px !important;

  `}
`;
const Select = styled.select`
  width: 200px;
  //   max-width: 350px;
  //   min-width: 250px;
  height: 40px;
  border: none;
  margin: 0.5rem 0;
  background-color: rgb(209, 207, 207);
  // background-color: #f5f5f5;
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease-in;

  &:hover {
    transform: translateY(-3px);
  }
`

const TextArea = styled.textarea`
  width: 80%;
  max-width: 350px;
  min-width: 250px;
  height: 75px;
  max-height: 50px;
  max-width: 200px;
  border: none;
  margin: 0.5rem 0;
  background-color: #f5f5f5;
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease-in;

  &:hover {
    transform: translateY(-3px);
  }
`;


const ImageShow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;

  img {
    height: 4rem;
    max-height: 4rem;
    overflow: hidden;
    border-radius: 2rem;
    transition: 0.4s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  h6 {
    color: #f94144;
  }
`


const CardIcon = styled(FaBeer)`
  width: 15px;
  height: 15px;
  /* color: white; */
  color: ${props => props.iconColor.colorValue};
`

const userObject={
    username: '',
    email: '',
    password: '',
    role: '',
    userData: '',
    password_length: 0,
}


export default function User() {

    let history = useHistory();

    const [values, setValues] = useState(userObject);

    //const { email, username, role, password, userData } = values;
    const { username, email, password, role, password_length } = values;


    const minPasswordLength = 12;

    const {user} = useContext(Context);
    const location = useLocation();
    const id = location.pathname.split("/")[2];

    if (user.user.role === 'user') {
        history.push('/')
    }


    const fetchUserInfo = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `/api/user/find/${id}`,
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
            data: null
        };

        await axios.request(config)
            .then(async (response) => {
                console.log(JSON.stringify(response.data));

                setValues(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(async (response) => {
        // call the function
        fetchUserInfo()
            // make sure to catch any error
            .catch(console.error);;
    },[id]);

    /*useEffect(() => {
        const getUser = async () => {
            try {
                const {data} = await axios.get('/api/user/find/' + id, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                })
                //console.log(data)
                setValues({
                    username: data.username,
                    email: data.email,
                    role: data.role,
                })
            } catch (error) {
                //console.log(error)
                setValues({...values})
            }
        };
        getUser()
    }, [id])*/


    const onChangeHandler = (name) => (e) => {
        const value = e.target.value;
        let userFormData = new FormData();
        userFormData.set(name, value);
        let a={};
        a[name]=value;
        console.log('onChangeHandler',{ ...values, a })
        if(name==='password'){
            console.log("===================------====================")
            console.log(value.length);
            setValues({ ...values, ['password_length']: value.length, [name]: value, userData: userFormData });
        }else{
            setValues({ ...values, [name]: value, userData: userFormData });
        }


    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = {
            username: values.username,
            email: values.email,
            role: values.role,
            password: values.password
        };

        console.log(`Attempting to Send:`,data)

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `/api/user/update/${id}`,
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.status === 200) {

                    setValues({
                        username: data.username,
                        email: data.email,
                        role: data.role,
                        password: data.password
                    });
                    toast.success(response.data.message, {
                        position: "top-center",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.warning(response.data.message, {
                        position: "top-center",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <>
            <Container>
                <Wrapper>
                    <FormContainer onSubmit={handleSubmit}>
                        <Title>
                            User Update Form
                        </Title>
                        <Form>
                            <LeftForm>
                                <label htmlFor="name">Username:</label>
                                <StyledInput onChange={onChangeHandler('username')} value={username}/>
                                <label htmlFor="name">Email:</label>
                                <StyledInput onChange={onChangeHandler('email')} value={email}/>
                            </LeftForm>
                            <RightForm>
                                <label htmlFor="name">Password: (length: {password_length} / {minPasswordLength}):</label>
                                <StyledInput type="password" onChange={onChangeHandler('password')} placeholder="*********" />
                                <label htmlFor="name">Role:</label>
                                <Select value={role} onChange={onChangeHandler('role')}>
                                    <option value="" hidden>
                                        Select a role
                                    </option>
                                    <option value="user">Standard</option>
                                    <option value="admin">Admin</option>

                                </Select>
                            </RightForm>
                        </Form>
                        <StyledButton type="submit" submit>Submit</StyledButton>
                    </FormContainer>
                </Wrapper>
            </Container>
        </>);

}
