import React, { useState, useContext } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutline } from '@material-ui/icons';
import { userRows } from "../../dummydata"
import { Link } from 'react-router-dom'
import "./usersList.css"
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Context } from '../../context/Context';
import axios from 'axios';
import { toast } from 'react-toastify'
import HoverableDiv from './HoverableDiv'
import { Modal } from '../../components/ModalCreate/Modal';
import styled from 'styled-components'

export default function UsersList(props) {
  const { users, setReloadUsers } = props
  //console.log(users)
  const { user } = useContext(Context);
  const [data, setData] = useState(userRows)
  const [credentials, setShowCredentials] = useState('');

  const handleDelete = (id) => {
    // setData(data.filter((item) => item.id !== id));
    // //console.log('Deleting');
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              // const res = await axios.post("/auth/register", {
              // const res = await axios.post(`${process.env.REACT_APP_API}/signup`, {
              // const res = await axios.post("http://localhost:8000/api/submitSession", state);
              //console.log(id)
              const { data } = await axios.delete(`/api/users/${id}`,
                {
                  headers: {
                    'Authorization': `Bearer ${user.token}`
                  }
                });

              toast.success('Update successful!', {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              //console.log(data)
              setReloadUsers(true)

            } catch (err) {
              //console.log(err)
              toast.success(`${err}`, {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

            }
          }
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ]
    });
  };

  const columns = [
    // { field: '_id', headerName: 'ID', width: 250 },
    // {
    //   field: 'host', headerName: 'Domain', width: 200, renderCell: (params) => {
    //     return (
    //       <div className="userListUser">
    //         {/* <img className="userListImg" src={params.row.avatar} alt="" /> */}
    //         {params.row.hostname}
    //       </div>
    //     )
    //   }
    // },
    { field: 'username', headerName: 'Name', width: 200 },
    { field: 'role', headerName: 'Role', width: 200 },
    {
      field: 'email',
      headerName: 'Email',
      //   type: 'number',
      width: 250,
    },
    // {
    //   field: 'password',
    //   headerName: 'cPanel Password',
    //   // type: 'password',
    //   width: 160,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <HoverableDiv password={params.row.password} />


    //       </>

    //     )
    //   }
    // },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/user/" + params.row._id}>
              <button className="userListEdit">Edit</button>
            </Link>
            {/* <button className="userListEdit" onClick={() => {
              setShowModal(prev => !prev)
              setShowCredentials(params.row)
            }} >Credentials</button> */}
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>

        )
      }
    }

  ];

  const [showModal, setShowModal] = useState(false);


  const openModal = () => {
    setShowModal(prev => !prev);
  };

  const StyledForm = styled.div`
  width: 100%;
  max-width: 700px;
  padding: 40px;
  /* background-color: #fff; */
  border-radius: 10px;
  box-sizing: border-box;
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  input{
    background-color: #d1cfcf;
  }
  >button{
    margin-top: 20px;
  }
`;



  return (
    <div className="usersList">
      <Modal showModal={showModal} setShowModal={setShowModal}>


      </Modal>
      {users ? <DataGrid rows={users} getRowId={(row) => row._id} disableSelectionOnClick columns={columns}  pageSize={10}  /> : <> No data </>}
      {/* {sessions.servers?.length === 0 ? (
                <div>empty</div>
              ): (
               <DataGrid rows={sessions.servers} getRowId={(row) => row._id} disableSelectionOnClick columns={columns} pageSize={10} checkboxSelection /> 
                // <div>hola</div>
              )} */}
      {/* <DataGrid rows={userRows} disableSelectionOnClick columns={columns} pageSize={10} checkboxSelection /> */}
    </div>
  )
}
