import React, { useState, useContext } from "react";

//All the svg files
import logo from "../../assets/logoHB.png";
import Home from "../../assets/home-solid.svg";
import Team from "../../assets/social.svg";
import Calender from "../../assets/sceduled.svg";
import Projects from "../../assets/starred.svg";
import Documents from "../../assets/draft.svg";
import PowerOff from "../../assets/power-off-solid.svg";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import {Context} from '../../context/Context'

const Container = styled.div`
  position: fixed;
  margin-top: 2.5vh;
  z-index: 99;
  .active {
    border-right: 4px solid var(--white);
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }
`;

const Button = styled.button`
  background-color: var(--black);
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin: 0.5rem 0 0 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::before,
  &::after {
    content: "";
    background-color: var(--white);
    height: 2px;
    width: 1rem;
    position: absolute;
    transition: all 0.3s ease;
  }
  &::before {
    top: ${(props) => (props.clicked ? "1.5" : "1rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }
  &::after {
    top: ${(props) => (props.clicked ? "1.2" : "1.5rem")};
    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }
`;

const SidebarContainer = styled.div`
  background-color: var(--black);
  width: 3.5rem;
  height: 80vh;
  margin-top: 1rem;
  border-radius: 0 30px 30px 0;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const Logo = styled.div`
  width: 2rem;
  img {
    width: 100%;
    height: auto;
  }
`;

const SlickBar = styled.ul`
  color: var(--white);
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--black);
  padding: 2rem 0;
  position: absolute;
  top: 6rem;
  left: 0;
  width: ${(props) => (props.clicked ? "12rem" : "3.5rem")};
  transition: all 0.5s ease;
  border-radius: 0 30px 30px 0;
`;

const Item = styled(NavLink)`
  text-decoration: none;
  color: var(--white);
  width: 100%;
  padding: 1rem 0;
  cursor: pointer;
  display: flex;
  padding-left: 1rem;
  &:hover {
    border-right: 4px solid var(--white);
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }
  img {
    width: 1.2rem;
    height: auto;
    filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg)
      brightness(78%) contrast(85%);
  }
`;

const Text = styled.span`
  width: ${(props) => (props.clicked ? "100%" : "0")};
  overflow: hidden;
  margin-left: ${(props) => (props.clicked ? "1.5rem" : "0")};
  transition: all 0.3s ease;
`;

const Profile = styled.div`
  width: ${(props) => (props.clicked ? "20rem" : "3rem")};
  height: 3rem;
  /* padding: 0.5rem 1rem; */
  /* border: 2px solid var(--white); */
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.clicked ? "12rem" : "0")};
  background-color: var(--black);
  color: var(--white);
  transition: all 0.3s ease;
  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      border: 2px solid var(--grey);
      padding: 2px;
    }
  }
`;

const Details = styled.div`
  display: ${(props) => (props.clicked ? "flex" : "none")};
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.div`
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4{
    display: inline-block;
  }

  a{
    font-size: 0%.8rem;
    text-decoration: none;
    color: var(--grey);
    
    &:hover{
      text-decoration: underline;
    }

  }
`

const Logout = styled.button`
  border: none;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  img {
    width: 100%;
    height: auto;
    filter: invert(15%) sepia(70%) saturate(6573%) hue-rotate(2deg)
      brightness(100%) contrast(126%);
    transition: all 0.3s ease;
    &:hover {
      border: none;
      padding: 0;
      opacity: 0.5;
    }
  }
`;
function Sidebar() {

  const { user,dispatch } = useContext(Context);

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click)
  const [profileClick, setProfileClick] = useState(false);
  const handleProfileClick = () => setProfileClick(!profileClick)

  const handleLogout = () => {
    dispatch({type: "LOGOUT"})
  }

  return (
    <Container>
      <Button clicked={click} onClick={handleClick}>Click</Button>
      <SidebarContainer>
        <Logo>
          <img src={logo} alt="logo" />
          {/* <img src={'http://hitbridge.com/wp-content/uploads/2019/01/hb-transparent.png'} alt="logo" /> */}
        </Logo>
        <SlickBar clicked={click}>
          <Item exact activeClassName="active" to="/" onClick={() => setClick(false)}>
            <img src={Home} alt="" />
            <Text clicked={click}>Home</Text>
          </Item>

          {user.user.role === 'user' ? '' : (
              <>
              <Item exact activeClassName="active" to="/users-panel" onClick={() => setClick(false)}>
                <img src={Team} alt="" />
                <Text clicked={click}>Users</Text>
              </Item>
              </>
            )}

          {/* <Item exact activeClassName="active" to="/usuarios" onClick={() => setClick(false)}>
            <img src={Team} alt="" />
            <Text clicked={click}>Usuarios</Text>
          </Item>
          <Item exact activeClassName="active" to="/ventas" onClick={() => setClick(false)}>
            <img src={Projects} alt="" />
            <Text clicked={click}>Ventas</Text>
          </Item>
          <Item exact activeClassName="active" to="/viajes" onClick={() => setClick(false)}>
            <img src={Calender} alt="" />
            <Text clicked={click}>Viajes</Text>
          </Item> */}
        </SlickBar>
        <Profile onClick={handleProfileClick} clicked={profileClick}>
          <img src="https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI" alt="Profile" />
          <Details  clicked={profileClick}>
         
              <Name>
        
                <Link to={"/me"}>
                         <h4>{user.user.username}</h4>
                </Link>
                            {/* <Link to={"/user/" + user.user._id}>
                  Update my Info
                </Link> */}
              </Name>
  

            <Logout onClick={handleLogout}>
              <img src={PowerOff} alt="logout" />
            </Logout>
          </Details>
        </Profile>
      </SidebarContainer>
    </Container>
  )
}

export default Sidebar
