import React, { useState } from "react";

export default function HoverableDiv(props) {
  const [display, setDisplay] = useState("notdisplayed");
  const showButton = e => {
    e.preventDefault();
    setDisplay("displayed");
  };

  const hideButton = e => {
    e.preventDefault();
    setDisplay("notdisplayed");
  };

  return (
      <div
        className="productbox"
        onMouseEnter={e => showButton(e)}
        onMouseLeave={e => hideButton(e)}
      >
        <span className={display}>{props.password}</span>
      </div>
  );
};